@import '~antd/dist/antd.css';

.StripeElement {
	margin-top: 10px;
	padding: 18px !important;
	border: 1px solid #cad1d7 !important;
	border-radius: 0.375rem !important;
}

.shadow.card>.border-0.card-header>div>i {
	cursor: pointer;
}

.demo-wrapper {
	height: 400px;
	border: 1px solid #cad1d7;
	border-radius: 0.375rem;
}

.toolbar-wrapper {
	display: none;
}

.pointer {
	cursor: pointer;
}